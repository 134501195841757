
@tailwind base;
@tailwind components;
@tailwind utilities;
html, body, #root {
  //min-height: 100vh;
  margin : 0;
  //height: auto;
}

body {
  margin: 0;
  font-family: Inter,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer base {
  button {
    @apply text-white;
    @apply font-semibold
  }
}
